@import '_colours.scss';
@import '_fonts.scss';
@import '_constants.scss';
body {
    font-family: $font;
    font-size: 16px;
    color: #FFF;
    background-color: $blue;
}
audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
    max-width: 100%;
}
img {
    height:auto !important;
}

.clear-float {
    clear:both;
}
h1,
h2,
h3,
h4 {
    font-family: $heading-font;
}
.italic {
    font-style: italic;
    font-weight: normal;
}
.uppercase {
    text-transform: uppercase;
}
.text-center {
    text-align: center;
}
.highlight,
.aqua {
    color: $aqua;
}
.highlight {
    font-family: $heading-font;
    font-style: italic;
    font-size: 22px;
}
.blue {
    color: $blue;
}
.button {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    padding: 10px 20px;
    max-width: 100%;
    color: #FFF;
    background-color: grey;
    border: 1px solid grey;
    box-sizing: border-box;
    transition: all 0.3s ease;
}
.button:hover {
    color: grey;
    background-color: transparent;
}

img.display-left {
    float:left;
    max-width: 50%;
    margin-right:20px;
    margin-bottom:10px;
}

img.display-right {
    float:right;
    max-width: 50%;
    margin-left:20px;
    margin-bottom:10px;
}

@media only screen and (max-width: 760px){
    img.display-left,
    img.display-right {
        float: none;
        max-width: 100%;
        margin: 20px 0;
    }
}